// GETリクエストでjavascriptファイルを読み込みコールバック関数で実行
//$.getScript("../js/jquery.jcimodal.js"); //modalのjsを読み込み

// GTM(GoogleTagManager)のカスタムイベント記録用
const gtmDL = (event, eventCategory, eventAction, eventLabel, eventValue = 1) => {
  // eventValue以外の引数どれかがundefだったら早期リターン
  const isUndefAny = [event, eventCategory, eventAction, eventLabel].some(v => v === undefined);
  if (isUndefAny) return false;
  //eventValue = (typeof eventValue === 'undefined') ? 1: eventValue;
  dataLayer.push({ event, eventCategory, eventAction, eventLabel, eventValue, });
};

//スマホ幅だったらtrue返却 20200615TM
const breakPoint = 768;
const isSP = window.innerWidth < breakPoint;

//iOS判定　iOSだったらtrue返却 20180620TM
const isiOS = () => {
  const ua = window.navigator.userAgent;
  const iOS_flag = /iPhone|iPad|iPod/.test(ua);
  const iPad_flag = ua.indexOf('iPad') > -1 || ua.indexOf('Macintosh') > -1 && 'ontouchend' in document; //2019年9月のiPadOS13から判定を加える
  return iOS_flag || iPad_flag;
};

//音声オプション マニュアル用 →を見つけてアイコンspanタグに置換
const replace_txtArrow = () => {
  $(".icons:contains('→') ").each((i, elem) => elem.innerHTML = elem.innerHTML.replace(/→/g,'<span class="font_arrow"></span>'));
};

// カレントURLをアクティブに&アクティブのタブまで自動スライド
const currentAutoSlide = () => {
  if ($(".naviContent li a").length != 0) {
    const url = window.location.href.split('/').pop();
    url = (url == "") ? "index.html" : url;
    url = (url.indexOf('s_') === 0) ? "support.html" : url;
    const current_tab = $('.naviContent li a[href*= "' + url + '"]');

    if (current_tab.length === 0) {
      $(".naviContent li:first-child a").addClass("product_current");
    } else {
      current_tab.addClass("product_current");
    }

    const slidable_navi = $('div.naviContent');//スライドするオブジェクト
    const slide_length = $(".naviContent li a[class*='product_current']").offset();//スライドする長さはcurrentタブの左まで
    if (slide_length !== undefined) {
      slidable_navi.animate({scrollLeft: slide_length.left});
    }
  }
};

//引数の文字列含むならtrue返却 20180710TM
//const searchStr = (searchstr, targetstr) => {
//  const searchRegEx = new RegExp(searchstr);
//  return (searchRegEx.test(targetstr));
//}

//要素存在判定　 20180711TM
const elemExist = selector => {
  const ex = (selector.includes('#')) ? document.getElementById(selector) : document.querySelector(selector);
  return (ex !== null);
};

//URLディレクトリ部分を取得
const getUrlDir = () => {
  // / → ''
  // /path → 'path'
  // /path/ → 'path'
  // /path/path2 → 'path2'
  // /path/path2/ → 'path2'
  // /path/path2.html → 'path'
  // /path/path2.php → 'path'

  // URLインターフェースはbabel効かない
  //const url = new URL(window.location.href);
  //const pathnames = url.pathname.split('/');
  //const dir = (pathnames.slice(-1)[0].includes('.')) ? pathnames[pathnames.length -2] : pathnames.slice(-1)[0];
  const url = window.location.href.split('/');
  const dir = url.slice(-2, -1)[0]; // 後ろから2番め
  return dir;
};

//htmlファイル名を取得
const getHtmlName = () => {
  const url = window.location.href.split('/');
  const filename = url.pop();
  return filename;
};

//URLパラメータ部分を取得
const getUrlParam = () => {
  const param = (location.search.length) ? location.search + "&" : "?";
  return param;
};

//URLパラメータを取得しobjに格納
const UrlParamtoArray = () => {
  const pair = location.search.substring(1).split('&');
  if (Object.keys(pair).length) { //パラメタがある場合
    const arg = {};
    pair.forEach(v => {
      if (v !== '') {
        const kv = v.split('=');
      arg[kv[0]] = kv[1];
    }
    });
    return arg;
  } else {
    return undefined;
  }
};

//タグつきテンプレートリテラル
const tagT = (str, ...val) => {
  if (!val.every(v => (v === 0)||v)) return ''; // リテラル配列のどれかがfalsy(ただし0はtrue扱いにする)→何も出力しない
  return str.map((k = '', i) => {
    const v = (typeof val[i] === 'boolean' || !val[i]) ? '' : val[i];
    return k + v;
  }).join('');
};

//指定場所までスクロール 引数は$オブジェクト指定
const onClickScrollPosition = ($selectorObj, adjust = 0) => {
  if ($selectorObj.length === 0) return; // $('')の指定は何もしない
  //const windowWidth = parseInt($(window).width());
  const pos = $selectorObj.offset().top;
  const scrollTo = pos - ($('#fixedBox').outerHeight(true) * 2) - adjust;
  const speed = (pos / 10) + 200;
  $('html,body').animate({scrollTop: scrollTo}, speed, 'swing');
};

//アコーディオンメニュー
//e:ハンドリングイベント
//init:初期表示状態//未使用
const accordion = (e, init = 'open') => {
  const elem = e.currentTarget;
  const $aco_s = $(elem);
  const $content = $aco_s.next();
  const acomenu_title = ($aco_s.data('title') !== undefined) ? $aco_s.data('title') : "注意事項";
  //const event = (e !== undefined) ? e.type : "open";

  const toggleStr = () => {
    const elemtxt = ($content.css('display') === 'none') ? '見る' : 'たたむ'; //コンテンツが閉じていたら、「～を見る」にセット
    return `${acomenu_title}を${elemtxt}`;
  };

  /*
  20181218 実際にしたい仕様:ロード時にaco_contentsをstyleどおりに表示する。
  load完了後、aco_contentsの表示状態によってスイッチの文字列も切り替える。
  ロード時クリック時共通:
  aco_contentsの表示を制御した後、when・doneを使って
  aco_contentsの表示状態によってスイッチの文字列も切り替える
  */

  //if ($content.css('display') === 'none') { //判断時コンテンツが閉じていたら、「～を見る」にセット
  //  return open;
  //} else {
  //  return close;
  //}

  if (e.type === 'load') { // 201812 デフォルトをスイッチ非表示、注意事項は全表示にしておきます
    // 20210108 loadのイベントが上手く行っていないが、以下の処理は特にしなくてもいい。
    if ($aco_s.data('acotype') !== 'click') {
      $aco_s.hide;
      $content.show;
    } else {
      $aco_s.text(toggleStr()); //要検討
    }
    return;
  }

  if (e.type === 'click') {
    $content.slideToggle('fast');
    return;
  }
};

// クリックされたタブに切替
// クリックするタブ部分ulに class="js-tab_area"をつける
// 切り替えるコンテンツ部分に  class="js-tabcontent_area"をつける
// クリックされた順番のところが連動して切り替わる
// indexはクリックされたタブindex
const tabSwitch = (e) => {
  const $clickTab = (e.type === 'click') ? $(e.target) : $('ul.js-tab_area li').eq(0); //e.targetはonでイベントが発生したオブジェクト。jqueryオブジェクトにラップ
  const index = (e.type === 'click') ? $clickTab.index() : 0; //クリックされたタブ番号.load時は0
  const $content = $('.js-tabcontent_area');
  const $targetContent = $content.eq(index); //クリックされたタブと同じ順番のコンテンツ

  $content.hide(); //コンテンツを一度すべて非表示
  $clickTab.siblings().removeClass('active'); //タブについているクラスactiveを一度すべて消す.siblingsは兄弟要素全て

  if (e.type === 'click') {
    const $tab_border = $(".border_b_blue");
    const border_color = (e.target.className.includes('marketbuy')) ? "#b9e6dc" : "#bbdefb"; //targetにmarketbuyのclassがあるか
    $tab_border.css("border-color", border_color);
  }
  $clickTab.addClass('active'); //クリックされたタブのみにクラスactiveをつける
  $targetContent.fadeIn("fast"); //クリックされたタブと同じ順番のコンテンツを表示

  setFlickity(index);
}

//classにsw-flickityがあれば、スマホ表示でflickityエレメントをセンターに
const setFlickity = (index) => {
  const flg_flickity = $(".sw-flickity").length;
  if (isSP && flg_flickity) {
    $(".sw-flickity").eq(index).flickity({
      "cellAlign": "center", //センター揃えはデフォルトなので不要
      "prevNextButtons": false
    });
  }
}

// data-selected属性につけた値と同じdata-showitem属性を付けたコンテンツが表示
const switchDisplay = (selected, showItemName) => {
  selected = (typeof selected === 'undefined') ? 'unselected' : selected; // IEはデフォルト引数の記述が使えないので
  showItemName = (typeof showItemName === 'undefined') ? 'showitem' : showItemName; // IEはデフォルト引数の記述が使えないので
  $(`[data-${showItemName}]`).hide(); // data属性セレクタの書き方に要注意
  if (selected !== 'unselected') {
    const $showItem = $(`[data-${showItemName}="${selected}"]`); // data属性セレクタの書き方に要注意
    $showItem.fadeIn("fast");
    onClickScrollPosition($showItem);
  }
}

// 選択するトリガーの要素にdata-selected属性をつける。
// data-selected属性につけた値と同じdata-showitem属性を付けたコンテンツが表示される
// data-selected属性とdata-showitem属性を対にすること
const selectSwitch = (e) => {
  const $selectbtn = $(e.target);
  const selectDataset = Object.keys(e.target.dataset); //押されたボタンのdatasetのキー名一覧
  const datasetName = selectDataset[0]; //押されたボタンのdataset名を取得
  const $selectbtns = ($selectbtn.siblings().length) ? $selectbtn.siblings() : $('[data-' + selectDataset + ']'); //同じdataset名を持つボタンか兄弟要素
  const selected = e.target.dataset.selected;

  $selectbtns.removeClass("btn_active btn_selectable").addClass("btn_unselect");
  $selectbtn.toggleClass("btn_active");
  switchDisplay(selected);
}

//iPadの旧iOSはグローバルメニューをflexbox使わない
const init_oldipad = () => {
  const ua = navigator.userAgent;
  if (ua.indexOf('iPad') > 0) {
    if (ua.indexOf('OS 4') > 0 || ua.indexOf('OS 5') > 0 || ua.indexOf('OS 6') > 0) {
      //vp = 'width=1000px';
      //$('head').prepend('<meta name="viewport" content="' + vp + '" id="viewport">');
      $("ul.globalmenu").removeClass("globalmenu").addClass("globalmenu2");
      $("ul.globalmenu_pad").removeClass("globalmenu_pad").addClass("globalmenu2");
      $("ul.globalmenu_pre").removeClass("globalmenu_pre").addClass("globalmenu2");
      $("ul.globalmenu_voice").removeClass("globalmenu_pre").addClass("globalmenu2");
    }
  }
};

//googleカスタム検索のカスタマイズ
const init_googlesearchbox = () => {
  $('#cse-search-box input[type="text"]').attr('placeholder', 'b-mobileサイト内検索').css({
    'background': '',
    'text-indent': '0'
  });
};

//menu現在地ハイライト
const hilightCurrent = () => {
  const product_menu = {
    kaimaku: ['globalmenu', 'current'],
    '190sim': ['globalmenu_pad', 'current_pad'],
    pad: ['globalmenu_pad', 'current_pad'],
    prepaid: ['globalmenu_pre', 'current2'],
    '7gbnm': ['globalmenu', 'current'],
    jfsimx: ['globalmenu_voice', 'current_voice'],
    jfsim3: ['globalmenu_voice', 'current_voice'],
    jfsim: ['globalmenu_voice', 'current_voice'],
    voice: ['globalmenu_voice', 'current_voice'],
    startsim: ['globalmenu_start', 'current_start'],
    prefix: ['globalmenu_prefix', 'current_prefix']
  };
  const hasGlobalmenu = (document.querySelector('ul[class*="globalmenu"]'));
  if (!hasGlobalmenu) { //globalmenuなかったら
    return false;
  }
  const dir = getUrlDir();
  const filename = getHtmlName();
  const isConvert = Object.keys(product_menu).includes(dir);
  const globalMenu_ul = isConvert ? `.${product_menu[dir][0]}` : `.globalmenu_${dir}`;
  const classforCurrent = isConvert ? product_menu[dir][1] : `current_${dir}`;
  const $currentMenu = $(`${globalMenu_ul} li a[href^="${filename}"]`);
  $currentMenu.addClass(classforCurrent); //ファイル名と同じhrefをCurrentに
  if (filename.match(/^support_/)) { //サポートのサブページならサポートメニューをCurrentに
    $(`${globalMenu_ul} a[href^="support"]`).addClass(classforCurrent);
  }
};

const gen_scrollStopEvent = () => { // http://www.risewill.co.jp/blog/archives/2618
  const scrollStopEvent = new $.Event('scrollstop'); //スクロールストップを検知するjQueryイベントオブジェクトを生成
  let timer;
  const scrollStopEventTrigger = () => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {$(window).trigger(scrollStopEvent)}, 200);
  }
  $(window).on('scroll', scrollStopEventTrigger);
  $('body').on('touchmove', scrollStopEventTrigger);
};

//header固定
const headerFix = () => {
  const $nav = $('#fixedBox');
  if ($(window).scrollTop() > 400) {
    $nav.addClass('fixed');
  } else {
    $nav.removeClass('fixed');
  }
};

// TOPに戻るボタンの表示・非表示
const disptoTOPButton = () => {
  const $element = $('.toTopButton');
  const scrollHeight = $(document).height();
  const scrollPosition = $(window).height() + $(window).scrollTop();
  const isScrolledBottom = ((scrollHeight - scrollPosition) / scrollHeight <= 0.05) ? true : false;
  if ($(window).scrollTop() > 1000 || isScrolledBottom) { // 現在スクロール位置が1000px以上または下まで到達
    $element.addClass('toTopButton-visible');
  } else {
    $element.removeClass('toTopButton-visible');
  }
};

// ボタンの高さを揃える 高さを揃える親要素にjs-heightAdjustをclass指定する
const heightAdjust = () => {
  const adjustBtn = document.querySelectorAll('.js-heightAdjust *[class^="btn"]');
  //複数のボタンの高さを取得し、配列に格納
  const arrayBtnHeight = Array.from(adjustBtn, elem => elem.offsetHeight); // adjustBtnはnodelistなので、第2引数に関数を渡すとmapしてくれる
  const maxHeight = Math.max(...arrayBtnHeight);
  adjustBtn.forEach((elem) => {
    elem.style.height = maxHeight + 'px'; // 最大の高さに揃える
  });
};

// 消費税・金額を税別→税込へ
const tax_disp = () => {
  const flg_intax = true; // 税別表記中はfalse
  const taxrate = (flg_intax) ? 1.10 : 1;
  const taxnote = (flg_intax) ? '特に表記がない場合、価格は税込表記です' : '特に表記がない場合、価格は税別表記です';
  $('.js-taxnote').text(taxnote);
  $('.js-price_tax').each((i, elem) => {
    const price = Number(elem.textContent);
    const priceinTax = (flg_intax) ? Math.floor(price * taxrate) : price;
    const price_txt = String(priceinTax).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') + '円';
    elem.textContent = price_txt;
  });
};

// クリップボードにコピー
const copyToClipboard = (text, copy = '') => {
  navigator.clipboard.writeText(text).then(
    () => alert(`${copy} copied to clipboard`), // Promiseのsuccess localhostやhttpでは失敗する
    () => alert('An error is occurd to copy'), // Promiseのエラー
  );
};

const imageSwitch_src = elem => {
  const srcname = elem.src;
  const srcarray = srcname.split('/');
  const filename_ex = srcarray.pop(); // 拡張子付きでファイル名だけ取得
  //const filename_ex = srcname.match(".+/(.+?)([\?#;].*)?$")[1]; // 拡張子付きでファイル名だけ取得
  const imgSrc = filename_ex.split('.');
  const imgPath = srcarray.join('/') + '/'; //末尾にはつかないので
  imgSrc[0] = imgSrc[0].replace(/_pc$|_sp$/g, '');
  let filename = imgSrc[0];
  if (isSP) { //画面幅=スマホ
    filename = imgSrc[0] + '_sp.' + imgSrc[1];
    filename = filename.replace(/_pc_sp/g, '_sp');
  } else {
    filename = imgSrc[0] + '_pc.' + imgSrc[1];
    filename = filename.replace(/_pc_pc/g, '_pc');
  }
  return imgPath + filename;
};


const imageInitload = () => {
  $('.js-image-switch').each((i, elem) => {
    elem.src = imageSwitch_src(elem);
  });
};

const imageSwitch = () => {
  $('.js-image-switch').each((i, elem) => {
    const img_src = elem.src;
    elem.src = (isSP) ? img_src.replace('_pc', '_sp') : img_src.replace('_sp', '_pc');
  });
};

document.addEventListener('DOMContentLoaded', function() {
  const elemExpand = document.querySelectorAll('.js-aco_switch, .js-aco_switch2');
  elemExpand.forEach(elem => { // 複数の要素にイベントリスナを付与
    // https://note.com/yamanoborer/n/n2e4cc40328b7
    elem.addEventListener('load', {handleEvent: accordion}, false); // loadより先にaddEventListener
    elem.addEventListener('click', {handleEvent: accordion}, false);
  })
});

$(() => {

  $(window).on('resize', () => {
    clearTimeout(resizeTimer);
    const resizeTimer = setTimeout(() => {
      imageInitload(); //レスポンシブ対応画像チェンジ
    }, 100);
    return false;
  });

  gen_scrollStopEvent();
  init_oldipad();
  init_googlesearchbox();
  hilightCurrent(); //menu現在地ハイライト
  imageInitload();
  replace_txtArrow();
  currentAutoSlide();
  heightAdjust();
  tax_disp();

  if (elemExist('ul.js-tab_area')) tabSwitch({type: 'load'}); // デフォルト、初期ロード時はコンテンツを開いた状態に
  $('ul.js-tab_area').on('click', 'li', tabSwitch); //第2引数のセレクタ指定でクリックされた要素をtabswitchに渡す

  const close_btn = document.querySelectorAll('.close_btn');
  [...close_btn].forEach(elem => {
    elem.addEventListener('click', () => { //flow_postご利用開始まで使われている
      const $btn = $(elem);
      $btn.prevAll('#flow_aco dd').slideToggle('fast');
      $btn.toggleClass("active"); //追加部分
      $btn.prev().slideToggle('fast');
    }, false);
  });

  $(window).on('scrollstop', () => { // スクロールが一時停止したら
    disptoTOPButton(); // スクロール位置によってTOPに戻るボタンを表示・非表示
    headerFix(); // スクロール位置によってナビゲーションメニューを表示・非表示
  });

  // TOPに戻るアニメーション
  $('.toTopButton').on('click', () => {
    $('html').animate({
      scrollTop: 0
    }, 'slow');
  });

  //タブ切り替え URLハッシュによって切り替え // URLハッシュで切り替える機能は後日実装 7/26
  let hash = location.hash; //#以降のハッシュ取得
  //hashの中に#tab～が存在するか調べて連想配列に
  hash = (hash.match(/^#tab\d+$/) || [])[0];
  //hashに要素が存在する場合、hashで取得した文字列（#tab2,#tab3等）から#より後を取得(tab2,tab3)
  const index = ($(hash).length) ? hash.slice(1) : 0;
});

export {
  gtmDL,
  isiOS,
  replace_txtArrow,
  currentAutoSlide,
  elemExist,
  getUrlDir,
  getHtmlName,
  getUrlParam,
  UrlParamtoArray,
  tagT,
  onClickScrollPosition,
  accordion,
  tabSwitch,
  setFlickity,
  switchDisplay,
  selectSwitch,
  init_oldipad,
  init_googlesearchbox,
  hilightCurrent,
  gen_scrollStopEvent,
  headerFix,
  disptoTOPButton,
  heightAdjust,
  tax_disp,
  copyToClipboard,
  imageInitload,
  imageSwitch
};

window.gtmDL = gtmDL;
window.isiOS = isiOS;
window.isSP = isSP;
//window.replace_txtArrow = replace_txtArrow;
//window.currentAutoSlide = currentAutoSlide;
window.elemExist = elemExist;
window.getUrlDir = getUrlDir;
window.getHtmlName = getHtmlName;
window.getUrlParam = getUrlParam;
window.UrlParamtoArray = UrlParamtoArray;
window.tagT = tagT;
window.onClickScrollPosition = onClickScrollPosition;
window.accordion = accordion;
window.tabSwitch = tabSwitch;
window.setFlickity = setFlickity;
window.switchDisplay = switchDisplay;
window.selectSwitch = selectSwitch;
//window.init_oldipad = init_oldipad;
//window.init_googlesearchbox = init_googlesearchbox;
//window.hilightCurrent = hilightCurrent;
//window.gen_scrollStopEvent = gen_scrollStopEvent;
window.headerFix = headerFix;
window.disptoTOPButton = disptoTOPButton;
//window.heightAdjust = heightAdjust;
//window.tax_disp = tax_disp;
window.copyToClipboard = copyToClipboard;
//window.imageInitload = imageInitload;
//window.imageSwitch = imageSwitch;
