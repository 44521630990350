  const get_allradio_val = () => { // どのラジオボタンがチェックされているか
    const $radio = $('input[type="radio"]');
    let radioNameArray = [];
    let radioValue = {};
    $.each($radio, (key, val) => { // radioのグループ名を取得
      radioNameArray.push(val.name);
    });
    radioNameArray = radioNameArray.filter((val, index, self) => self.indexOf(val) === index); // 重複を除く
    $.each(radioNameArray, (namekey, nameval) => {
      let $radioGroup = $(`input[name="${nameval}"]`);
      radioValue[nameval] = {};
      $.each($radioGroup, (key, val) => {
        radioValue[nameval][val.value] = val.checked;
      });
    });
    return radioValue;
  };
  
  // 購入画面へ遷移
  const goPurchase = function(applypkg_obj) {
    // 
    //if (typeof pkg === 'undefined') {
    //  alert("申込パッケージの型番を確認して選んでください。");
    //  return false;
    //}
    //if (typeof myb === 'undefined') { //
    //  alert("日本通信IDをお持ちか選択してください。");
    //  return false;
    //}

    const productobj = { //radioに対応するURL引数の配列
      only: 'only',
      simset: 'simset',
      login: 'checkout/sdentry',
      unlogin: 'accounts/signup',
    }
    const baseurl = "https://mypage.bmobile.ne.jp/";
    const ga_purchase_item = 'am530' + applypkg_obj.connector + '_' + applypkg_obj.myb;
    ga('send', 'event', 'Order', 'Order', ga_purchase_item);
    const url = `${baseurl}${productobj[applypkg_obj.myb]}?kind=${productobj[applypkg_obj.connector]}`;//対応するURL変数をセット
    location.href = url; //選んだプロダクトに対応する申込開始ページへ遷移
  };

  const radioGroupCheck = (radioObj) => {
    return Object.values(radioObj).some(val => val); // どちらかtrueならtrue返却
  };

  const clickNext = () => {
    const radioValue = get_allradio_val();
    const isAllChecked = Object.keys(radioValue).every((key) => { // すべてがtrueならtrue返却
      return radioGroupCheck(radioValue[key]);
    });
    if (isAllChecked) {
      $('.js-purchase_am530').removeClass('btn_click-disable');
    }
    onClickScrollPosition($(".voiceflow_arrow-green:first"));
  };

$(function() {
  $('.js-purchase_am530').on("click", () => { //購入画面に移動する「次へ」ボタン
    const radioValue = get_allradio_val();
    const isAllChecked = Object.keys(radioValue).every((key) => { // すべてがtrueならtrue返却
      return radioGroupCheck(radioValue[key]);
    });
    if (!isAllChecked) {
      alert("すべて選択してください。");
      return false; // ラジオがすべてチェックされていないなら何もしない
    }

    const applypkg_obj = {
      connector: $('input[name=connector]:checked').val(),
      myb: $('input[name=myb]:checked').val(),
    };
    goPurchase(applypkg_obj);
  });
});

export {clickNext};
window.clickNext = clickNext;